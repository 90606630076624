@import "custom";

:root {
  --bold: 600;
  --semibold: 500;
  --normal: 400;
  --lighter: 300;
}

button {
  appearance: none;
  border: none;
  background-color: #0a0a0a;
}

.btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #0a0a0a;
  --bs-btn-border-color: #0a0a0a;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #0a0a0a;
  --bs-btn-hover-border-color: #0a0a0a;
  --bs-btn-focus-shadow-rgb: 10, 10, 10;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #0a0a0a;
  --bs-btn-active-border-color: #0a0a0a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #0a0a0a;
  --bs-btn-disabled-border-color: #0a0a0a;
}

.c-mb-10 {
  margin-bottom: 10px;
}
