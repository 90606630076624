.phone-input .dialing-code {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e1e1e1;
  background-color: #0a0a0a;
  border-radius: 6px 0px 0px 6px;
  padding: 6px 12px;
  gap: 8px;
  color: white;
}

.phone-input input[type="text"] {
  padding: 1rem;
}

.phone-input .btn[type="submit"] {
  --bs-btn-color: #ffffff;
  font-weight: var(--bold);
  margin-block: 0.875rem;
}

.phone-input .dialing-code img {
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1/1;
}
